<template>
  <div class="menu">
    <div class="background"></div>
    <router-link @click="$emit('closeMenu')" :to="{ name: 'Home' }">
      <div class="logo">
        <div class="title">
          <img src="@/assets/logo.svg" alt="" />
          <img src="@/assets/logo-green.svg" alt="" />
        </div>
      </div>
    </router-link>
    <div class="links">
      <div class="loading" v-if="loading">
        <h3>...</h3>
      </div>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div v-if="content" class="pages">
        <h1 v-for="(page, index) in content" :key="index">
          <router-link @click="$emit('closeMenu')" :to="`/${page.slug.current}/`">
            {{ page.title }}
          </router-link>
        </h1>
      </div>
    </div>
    <div class="about">
      <router-link @click="$emit('closeMenu')" :to="{ name: 'About' }">
        <h1>About</h1>
      </router-link>
    </div>
  </div>
</template>

<script>
import sanity from "../client";

const query = `*[_type == "content"] {
  title,
  slug,
}`;

export default {
  inject: ["mq"],
  data() {
    return {
      content: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      this.loading = true;

      sanity.fetch(query).then(
        (content) => {
          this.loading = false;
          this.content = content;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style lang="scss">
.menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 3;
  left: 0;
  transition: all 0.2s;

  .background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.7;
    z-index: -1;
  }

  .logo,
  .links,
  .pages,
  .about {
    display: flex;
    justify-content: center;
  }

  .logo {
    height: 50vh;
    align-items: flex-end;

    .title {
      position: relative;
      text-align: center;

      img {
        height: 32vh;
        max-width: 110vw;
      }

      img:nth-child(2) {
        position: absolute;
        top: -5px;
        left: 0;
      }
    }
  }

  .links .pages{
    height: 22vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about {
    padding-top: 5vh;
    align-items: flex-start;
  }

  &.fade-enter-active,
  &.fade-leave-to {
    opacity: 0;
  }

  .links a:hover,
  .about a:hover {
    color: #01ff76;
  }
}
</style>
