import { createApp } from "vue";
import { Vue3Mq } from "vue3-mq";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);
app.use(Vue3Mq, {
  breakpoints: { 
    xs: 0,
    xl: 768,
  }
})
app.use(router)
app.mount('#app')

