<template>
  <div class="home" @click="openMenu">
    <video src="@/assets/mainpage.mp4" type="video/mp4" muted playsinline autoplay loop></video>
    <div class="logo">
      <div class="title">
        <img src="@/assets/logo-green.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    openMenu() {
      document.querySelector("nav .icon").click()
    }
  }
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    margin: 0;
  }

  .logo {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: flex-end;

    img {
      height: 32vh;
      max-width: 110vw;
    }
  }
}
</style>
