<template>
  <nav>
    <div
      class="icon"
      @click="isOpen = !isOpen"
      :class="[{ open: isOpen }, { mobile: this.mq.xs }]"
    >
      <div class="bar"></div>
    </div>
    <transition name="fade">
      <Menu @closeMenu="isOpen = false" v-show="isOpen" />
    </transition>
  </nav>
</template>

<script>
import Menu from "@/components/Menu.vue";
export default {
  inject: ["mq"],
  components: { Menu },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="scss">
.icon {
  cursor: pointer;
  height: 44px;
  position: fixed;
  z-index: 10;
  top: 47px;
  right: 60px;
  width: 40px;
  transition: 0.5s all;

  &.mobile {
    top: 27px;
    right: 35px;

    &.open {
      transform: translateX(10px);
    }
  }

  .bar {
    margin-top: 15px;
    width: 50px;
    height: 4px;
    background-color: #01ff76;
    transition: 0.5s all;

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 50px;
      height: 4px;
      background-color: #01ff76;
      transition: 0.5s all;
    }

    &:before {
      transform: translateY(-12px);
    }

    &:after {
      transform: translateY(12px);
    }
  }
  &.open {
    .bar {
      transform: rotate(45deg);

      &:before,
      &:after {
        visibility: visible;
        transform-origin: right right;
        transform: translateY(0);
      }

      &:after {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
