<template>
  <div id="about">
    <div class="loading" v-if="loading">
      <h3>...</h3>
    </div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div class="content" v-if="about">
      <div class="title">
        <router-link :to="{ name: 'Home'}">
          <h1>{{ about.title }}</h1>
        </router-link>
      </div>
      <div class="text" :class="mq.current">
        <h2><SanityBlocks :blocks="blocks" /></h2>
        <div class="links">
          <div v-if="about.email">
            <h1><a :href="'mailto:' + about.email">EMAIL</a></h1>
          </div>
          <div v-if="about.social" class="social">
            <h1 v-for="(link, index) in about.social" :key="index">
              <a :href="link.url" target="_blank">{{ link.title }}</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SanityBlocks } from "sanity-blocks-vue-component";
import sanity from "../client";

const query = `*[_type == "info"] {
  title,
  text,
  email,
  social,
}[0]`;

export default {
  inject: ["mq"],
  components: { SanityBlocks },
  data() {
    return {
      about: [],
      blocks: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      this.loading = true;

      sanity.fetch(query).then(
        (about) => {
          this.loading = false;
          this.about = about;
          this.blocks = about.text;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style lang="scss">
#about {
  margin: 30px;
  width: calc(100% - 100px);
  max-width: 1000px;

  .title {
    padding-top: 1px;
    padding-bottom: 10px;
  }

  .text {

    p {
      font-size: 27px;
      opacity: 0.9;
      line-height: 110%;
    }

    &.xs {
      padding: 0;

      p {
        font-size: 20px;
        line-height: 110%;
        opacity: 1;
        color: #01ff76;
      }

      .links {
        color: white;
      }
    }

    .links {
      padding-top: 10px;
      color: #01ff76;

      div {
        padding-bottom: 15px;
      }

      h1 {
        font-size: 25px;
        padding-bottom: 15px;
      }
    }
  }
}
</style>
