<template>
  <div class="main" :class="mq.current">
    <Nav />
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  inject: ["mq"],
  components: { Nav }, 
};
</script>

<style lang="scss">
@font-face {
  font-family: "Avara_Bold";
  src: local("Avara-Bold"), url("./fonts/Avara/Avara-Bold.woff") format("woff"),
    url("./fonts/Avara/Avara-Bold.woff2") format("woff2"),
    url("./fonts/Avara/Avara-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avara_Bold Italic";
  src: local("Avara-Bold Italic"),
    url("./fonts/Avara/Avara-BoldItalic.woff") format("woff"),
    url("./fonts/Avara/Avara-BoldItalic.woff2") format("woff2"),
    url("./fonts/Avara/Avara-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background: black;
  color: white;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}


h1 {
  font-family: Avara_Bold Italic, sans-serif;
  font-size: 30px;
  letter-spacing: -1.5px;
  line-height: 0;
  text-transform: lowercase;
}

h2, p {
  font-family: Avara_Bold, sans-serif;
  font-size: 36px;
  letter-spacing: -0.5px;
  line-height: 95%;
}

h3 {
  font-family: Avara_Bold Italic, sans-serif;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 95%;
}

.xs p {
  font-family: Avara_Bold, sans-serif;
  font-size: 24px;
  line-height: 95%;
}

.green {
  color: #01ff76;
}

</style>
